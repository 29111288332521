<template>
  <div>
    <Layout>
      <PageHeader :title="title" :items="items" />
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">User Learn Detail</h4>
              <p class="card-title-desc">
                수강중인 코스에 대한 상세 정보를 확인합니다.
              </p>
              <div class="table-responsive">
                <table class="table table-hover mb-0 text-center">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>아이디</th>
                      <th>강의명</th>
                      <th>상태</th>
                      <th>전체 강의수</th>
                      <th>완료 강의수</th>
                      <th>시작일</th>
                      <th>종료일</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>devsuya@kakao.com</td>
                      <td>핵심만 골라 배우는 HTML5</td>
                      <td>
                        <span class="badge bg-primary">수강중</span>
                        <!-- <span class="badge bg-danger">수강종료</span> -->
                      </td>
                      <td>70</td>
                      <td>60</td>
                      <td>2022-10-22 12:22:00</td>
                      <td>2022-10-22 12:22:00</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  </div>
</template>

<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
export default {
  page: {
    title: 'User',
    meta: [{name: 'description', content: appConfig.description}]
  },
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: 'User Learn Detail',
      items: [
        {
          text: 'User',
          href: '/'
        },
        {
          text: 'Learn',
          href: '/'
        },
        {
          text: 'User Learn Detail',
          active: true
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped></style>
